import React from "react";
import styles from "./TrustedBy.module.css";

const TrustedBySection = () => {
  const logos = [
    "/harvard.png",
    "/crick.png",
    "/penn.png",
    "/yale.png",
    "/wyss.png",
  ];

  return (
    <div className={styles.trustedByContainer}>
      <h2 className={styles.trustedByTitle}>Trusted by scientists at</h2>
      <div className={styles.logoGrid}>
        {logos.map((logo, index) => (
          <div key={index} className={styles.logoWrapper}>
            <img src={logo} alt="Institution logo" className={styles.logo} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustedBySection;
