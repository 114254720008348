import PropTypes from "prop-types";
import styles from "./PaymentTierTwo.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierTwo = ({ className = "" }) => {
  const { professional } = paymentTiersCopy;

  const handlePurchase = () => {
    window.open(professional.button.url, "_blank", "noopener,noreferrer");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handlePurchase();
    }
  };

  return (
    <div
      className={[styles.paymentTierTwo, className].join(" ")}
      role="listitem"
      aria-labelledby="professional-tier-title"
    >
      <div className={styles.paymentTierTwoChild} role="presentation" />
      <div className={styles.professionalContent}>
        <h2 id="professional-tier-title" className={styles.professional}>
          {professional.title}
        </h2>
        <div
          className={styles.professionalPricing}
          aria-label="Pricing information"
        >
          <b className={styles.priceSymbol}>
            {professional.pricing.price}
            <span className={styles.perMonth}>/month</span>
          </b>
          {/* <b className={styles.perLabchip}>{professional.pricing.subtitle}</b> */}
        </div>
        <button
          className={styles.signUp}
          onClick={handlePurchase}
          onKeyPress={handleKeyPress}
          aria-label={`Purchase ${professional.title} plan`}
        >
          <div className={styles.purchaseNow}>{professional.button.text}</div>
        </button>
      </div>
      <div className={styles.professionalDetails}>
        <div className={styles.topFeature}>{professional.features[0]}</div>
        <ul className={styles.featureList}>
          {professional.features.slice(1).map((feature, index) => (
            <li key={index} className={styles.featureItem}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

PaymentTierTwo.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierTwo;
