import PropTypes from "prop-types";
import styles from "./PaymentTierThree.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierThree = ({ className = "" }) => {
  const { enterprise } = paymentTiersCopy;

  const handleContactUs = () => {
    window.location.href = enterprise.button.url;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleContactUs();
    }
  };

  return (
    <div
      className={[styles.paymentTierThree, className].join(" ")}
      role="listitem"
      aria-labelledby="enterprise-tier-title"
    >
      <div className={styles.paymentTierThreeChild} role="presentation" />
      <div className={styles.enterpriseContent}>
        <h2 id="enterprise-tier-title" className={styles.enterprise}>
          {enterprise.title}
        </h2>
      </div>
      <div className={styles.enterpriseDescription}>
        <b className={styles.customSolutionsAvailableForContainer}>
          {enterprise.description.map((line, index) => (
            <p key={index} className={styles.customSolutionsAvailable}>
              {line}
            </p>
          ))}
        </b>
      </div>
      <button
        className={styles.signUp}
        onClick={handleContactUs}
        onKeyPress={handleKeyPress}
        aria-label="Contact us about Enterprise plan"
      >
        <div className={styles.contactUs}>{enterprise.button.text}</div>
      </button>
      <div className={styles.security}>
        <div className={styles.topFeature}>{enterprise.features[0]}</div>
        <ul className={styles.featureList}>
          {enterprise.features.slice(1).map((feature, index) => (
            <li key={index} className={styles.featureItem}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

PaymentTierThree.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierThree;
