import PropTypes from "prop-types";
import styles from "./PaymentTierOne.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierOne = ({ className = "" }) => {
  const { academic } = paymentTiersCopy;

  const handlePurchase = () => {
    window.open(academic.button.url, "_blank", "noopener,noreferrer");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handlePurchase();
    }
  };

  return (
    <div
      className={[styles.paymentTierOne, className].join(" ")}
      role="listitem"
      aria-labelledby="academic-tier-title"
    >
      <div className={styles.paymentTierOneChild} role="presentation" />
      <div className={styles.academicContent}>
        <h2 id="academic-tier-title" className={styles.academic}>
          {academic.title}
        </h2>
        <div
          className={styles.academicPricing}
          aria-label="Pricing information"
        >
          <b
            className={
              academic.pricing.price === "FREE"
                ? styles.freePrice
                : styles.priceSymbol
            }
          >
            {academic.pricing.price}
          </b>
          {/* <b className={styles.perLabchip}>{academic.pricing.subtitle}</b> */}
        </div>
        <button
          className={styles.signUp}
          onClick={handlePurchase}
          onKeyPress={handleKeyPress}
          aria-label={`Purchase ${academic.title} plan`}
        >
          <div className={styles.purchaseNow}>{academic.button.text}</div>
        </button>
      </div>
      <div className={styles.academicDetails}>
        <div className={styles.topFeature}>{academic.features[0]}</div>
        <ul className={styles.featureList}>
          {academic.features.slice(1).map((feature, index) => (
            <li key={index} className={styles.featureItem}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

PaymentTierOne.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierOne;
