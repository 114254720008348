import PropTypes from "prop-types";
import styles from "./ActionBox.module.css";

const ActionBox = ({
  className = "",
  header = "",
  body = "",
  image = "",
  imageAlt = "",
}) => {
  return (
    <article
      className={[styles.actionBoxContainer, className].join(" ")}
      role="region"
      aria-labelledby="action-header"
    >
      <div className={styles.textContent}>
        <h2 id="action-header" className={styles.header}>
          {header}
        </h2>
        <p className={styles.body}>{body}</p>
      </div>

      {image && (
        <figure className={styles.imageWrapper}>
          <img
            src={image}
            alt={imageAlt}
            className={styles.image}
            tabIndex="0"
          />
        </figure>
      )}
    </article>
  );
};

ActionBox.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default ActionBox;
