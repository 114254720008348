import React, { forwardRef } from "react";
import styles from "../pages/Desktop.module.css";
import ActionBox from "./ActionBox.js";
import TrustedBySection from "./TrustedBy.js";
import trustedByStyles from "./TrustedBy.module.css";
import { secondSectionCopy } from "./copy";

const SecondSection = forwardRef(({ className = "" }, ref) => {
  return (
    <div
      ref={ref}
      className={styles.secondSection}
      role="region"
      aria-label="Product Features"
    >
      <div className={styles.coloredLinesFixed}>
        <img
          src="/colored-line-shapes.svg"
          alt=""
          role="presentation"
          aria-hidden="true"
        />
      </div>
      <TrustedBySection />
      <section
        className={styles.backgroundParent}
        role="presentation"
        aria-hidden="true"
      >
        <div className={styles.background} />
        <div className={styles.gradient} />
      </section>

      {secondSectionCopy.actionBoxes.map((box, index) => (
        <ActionBox
          key={index}
          direction={box.direction}
          header={box.header}
          body={box.body}
          image={box.image}
        />
      ))}
    </div>
  );
});

export default SecondSection;
